.ChangeUrl{
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  .changForm{
    max-width: 650px;
    width: 100%;
    padding: 0px 30px;
    border-radius: 10px;
    box-shadow: 0px 4px 15px 0px rgba(102,102,102,0.06);
    border: 1px solid rgba(102,102,102,0.06);
    display: flex;
    flex-direction: column;
    align-items: center;
    .title{
      font-size: 24px;
      text-align: center;
    }
    .Input{
      width: 100%;
      padding: 10px;
    }
    .btn{
      width: 350px;
      background-color: rgb(3, 184, 119);
      border: rgb(3, 184, 119);
      border-radius: 40px;
      height: 50px;
      color: rgb(255, 255, 255);
      font-size: 25px;
      margin: 40px 0 20px;
    }
  }
}